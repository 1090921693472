/* tslint:disable */
/* eslint-disable */
/**
 * Sell Checkout API
 * Sell Checkout API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-vilnius@simplex.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CurrenciesCryptoCurrenciesInner
 */
export interface CurrenciesCryptoCurrenciesInner {
    /**
     * 
     * @type {string}
     * @memberof CurrenciesCryptoCurrenciesInner
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CurrenciesCryptoCurrenciesInner
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof CurrenciesCryptoCurrenciesInner
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof CurrenciesCryptoCurrenciesInner
     */
    decimals?: number;
    /**
     * 
     * @type {string}
     * @memberof CurrenciesCryptoCurrenciesInner
     */
    networkName: string;
    /**
     * 
     * @type {number}
     * @memberof CurrenciesCryptoCurrenciesInner
     */
    minAmount?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CurrenciesCryptoCurrenciesInner
     */
    imageUrl: string;
    /**
     * 
     * @type {number}
     * @memberof CurrenciesCryptoCurrenciesInner
     */
    defaultAmount?: number;
}

/**
 * Check if a given object implements the CurrenciesCryptoCurrenciesInner interface.
 */
export function instanceOfCurrenciesCryptoCurrenciesInner(value: object): value is CurrenciesCryptoCurrenciesInner {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('code' in value) || value['code'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('networkName' in value) || value['networkName'] === undefined) return false;
    if (!('imageUrl' in value) || value['imageUrl'] === undefined) return false;
    return true;
}

export function CurrenciesCryptoCurrenciesInnerFromJSON(json: any): CurrenciesCryptoCurrenciesInner {
    return CurrenciesCryptoCurrenciesInnerFromJSONTyped(json, false);
}

export function CurrenciesCryptoCurrenciesInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): CurrenciesCryptoCurrenciesInner {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'code': json['code'],
        'name': json['name'],
        'decimals': json['decimals'] == null ? undefined : json['decimals'],
        'networkName': json['networkName'],
        'minAmount': json['minAmount'] == null ? undefined : json['minAmount'],
        'imageUrl': json['imageUrl'],
        'defaultAmount': json['defaultAmount'] == null ? undefined : json['defaultAmount'],
    };
}

  export function CurrenciesCryptoCurrenciesInnerToJSON(json: any): CurrenciesCryptoCurrenciesInner {
      return CurrenciesCryptoCurrenciesInnerToJSONTyped(json, false);
  }

  export function CurrenciesCryptoCurrenciesInnerToJSONTyped(value?: CurrenciesCryptoCurrenciesInner | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'code': value['code'],
        'name': value['name'],
        'decimals': value['decimals'],
        'networkName': value['networkName'],
        'minAmount': value['minAmount'],
        'imageUrl': value['imageUrl'],
        'defaultAmount': value['defaultAmount'],
    };
}

