/* tslint:disable */
/* eslint-disable */
/**
 * Sell Checkout API
 * Sell Checkout API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-vilnius@simplex.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { KycCollectionStatus } from './KycCollectionStatus';
import {
    KycCollectionStatusFromJSON,
    KycCollectionStatusFromJSONTyped,
    KycCollectionStatusToJSON,
    KycCollectionStatusToJSONTyped,
} from './KycCollectionStatus';

/**
 * 
 * @export
 * @interface TransactionKycCollection
 */
export interface TransactionKycCollection {
    /**
     * 
     * @type {string}
     * @memberof TransactionKycCollection
     */
    inquiryId: string;
    /**
     * 
     * @type {KycCollectionStatus}
     * @memberof TransactionKycCollection
     */
    status: KycCollectionStatus;
}



/**
 * Check if a given object implements the TransactionKycCollection interface.
 */
export function instanceOfTransactionKycCollection(value: object): value is TransactionKycCollection {
    if (!('inquiryId' in value) || value['inquiryId'] === undefined) return false;
    if (!('status' in value) || value['status'] === undefined) return false;
    return true;
}

export function TransactionKycCollectionFromJSON(json: any): TransactionKycCollection {
    return TransactionKycCollectionFromJSONTyped(json, false);
}

export function TransactionKycCollectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): TransactionKycCollection {
    if (json == null) {
        return json;
    }
    return {
        
        'inquiryId': json['inquiryId'],
        'status': KycCollectionStatusFromJSON(json['status']),
    };
}

  export function TransactionKycCollectionToJSON(json: any): TransactionKycCollection {
      return TransactionKycCollectionToJSONTyped(json, false);
  }

  export function TransactionKycCollectionToJSONTyped(value?: TransactionKycCollection | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'inquiryId': value['inquiryId'],
        'status': KycCollectionStatusToJSON(value['status']),
    };
}

