/* tslint:disable */
/* eslint-disable */
/**
 * Sell Checkout API
 * Sell Checkout API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-vilnius@simplex.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FiatPayoutStatus } from './FiatPayoutStatus';
import {
    FiatPayoutStatusFromJSON,
    FiatPayoutStatusFromJSONTyped,
    FiatPayoutStatusToJSON,
    FiatPayoutStatusToJSONTyped,
} from './FiatPayoutStatus';
import type { PaymentMethod } from './PaymentMethod';
import {
    PaymentMethodFromJSON,
    PaymentMethodFromJSONTyped,
    PaymentMethodToJSON,
    PaymentMethodToJSONTyped,
} from './PaymentMethod';

/**
 * Fiat payout aggregate
 * @export
 * @interface FiatPayout
 */
export interface FiatPayout {
    /**
     * Fiat payout identifier
     * @type {string}
     * @memberof FiatPayout
     */
    id: string;
    /**
     * 
     * @type {FiatPayoutStatus}
     * @memberof FiatPayout
     */
    status: FiatPayoutStatus;
    /**
     * transaction id
     * @type {string}
     * @memberof FiatPayout
     */
    transactionId: string;
    /**
     * 
     * @type {PaymentMethod}
     * @memberof FiatPayout
     */
    paymentMethod: PaymentMethod;
    /**
     * returned if status === complete. indicates if the fiat payout process finished successfully or not
     * 
     * @type {string}
     * @memberof FiatPayout
     */
    result?: FiatPayoutResult;
}


/**
 * @export
 */
export const FiatPayoutResult = {
    Success: 'success',
    Fail: 'fail'
} as const;
export type FiatPayoutResult = typeof FiatPayoutResult[keyof typeof FiatPayoutResult];


/**
 * Check if a given object implements the FiatPayout interface.
 */
export function instanceOfFiatPayout(value: object): value is FiatPayout {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('status' in value) || value['status'] === undefined) return false;
    if (!('transactionId' in value) || value['transactionId'] === undefined) return false;
    if (!('paymentMethod' in value) || value['paymentMethod'] === undefined) return false;
    return true;
}

export function FiatPayoutFromJSON(json: any): FiatPayout {
    return FiatPayoutFromJSONTyped(json, false);
}

export function FiatPayoutFromJSONTyped(json: any, ignoreDiscriminator: boolean): FiatPayout {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'status': FiatPayoutStatusFromJSON(json['status']),
        'transactionId': json['transactionId'],
        'paymentMethod': PaymentMethodFromJSON(json['paymentMethod']),
        'result': json['result'] == null ? undefined : json['result'],
    };
}

  export function FiatPayoutToJSON(json: any): FiatPayout {
      return FiatPayoutToJSONTyped(json, false);
  }

  export function FiatPayoutToJSONTyped(value?: FiatPayout | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'status': FiatPayoutStatusToJSON(value['status']),
        'transactionId': value['transactionId'],
        'paymentMethod': PaymentMethodToJSON(value['paymentMethod']),
        'result': value['result'],
    };
}

