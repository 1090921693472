/* tslint:disable */
/* eslint-disable */
/**
 * Sell Checkout API
 * Sell Checkout API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-vilnius@simplex.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PaymentMethod } from './PaymentMethod';
import {
    PaymentMethodFromJSON,
    PaymentMethodFromJSONTyped,
    PaymentMethodToJSON,
    PaymentMethodToJSONTyped,
} from './PaymentMethod';

/**
 * 
 * @export
 * @interface PaymentInstrumentResponse
 */
export interface PaymentInstrumentResponse {
    /**
     * 
     * @type {string}
     * @memberof PaymentInstrumentResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentInstrumentResponse
     */
    applicantId: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentInstrumentResponse
     */
    reference: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentInstrumentResponse
     */
    transactionId: string;
    /**
     * 
     * @type {PaymentMethod}
     * @memberof PaymentInstrumentResponse
     */
    paymentMethod: PaymentMethod;
}



/**
 * Check if a given object implements the PaymentInstrumentResponse interface.
 */
export function instanceOfPaymentInstrumentResponse(value: object): value is PaymentInstrumentResponse {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('applicantId' in value) || value['applicantId'] === undefined) return false;
    if (!('reference' in value) || value['reference'] === undefined) return false;
    if (!('transactionId' in value) || value['transactionId'] === undefined) return false;
    if (!('paymentMethod' in value) || value['paymentMethod'] === undefined) return false;
    return true;
}

export function PaymentInstrumentResponseFromJSON(json: any): PaymentInstrumentResponse {
    return PaymentInstrumentResponseFromJSONTyped(json, false);
}

export function PaymentInstrumentResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentInstrumentResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'applicantId': json['applicantId'],
        'reference': json['reference'],
        'transactionId': json['transactionId'],
        'paymentMethod': PaymentMethodFromJSON(json['paymentMethod']),
    };
}

  export function PaymentInstrumentResponseToJSON(json: any): PaymentInstrumentResponse {
      return PaymentInstrumentResponseToJSONTyped(json, false);
  }

  export function PaymentInstrumentResponseToJSONTyped(value?: PaymentInstrumentResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'applicantId': value['applicantId'],
        'reference': value['reference'],
        'transactionId': value['transactionId'],
        'paymentMethod': PaymentMethodToJSON(value['paymentMethod']),
    };
}

