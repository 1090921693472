/* tslint:disable */
/* eslint-disable */
/**
 * Sell Checkout API
 * Sell Checkout API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-vilnius@simplex.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SepaPaymentInstrumentResponse
 */
export interface SepaPaymentInstrumentResponse {
    /**
     * 
     * @type {string}
     * @memberof SepaPaymentInstrumentResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof SepaPaymentInstrumentResponse
     */
    applicantId: string;
    /**
     * 
     * @type {string}
     * @memberof SepaPaymentInstrumentResponse
     */
    iban: string;
    /**
     * 
     * @type {string}
     * @memberof SepaPaymentInstrumentResponse
     */
    beneficiaryFullName?: string;
    /**
     * 
     * @type {string}
     * @memberof SepaPaymentInstrumentResponse
     */
    beneficiaryBankName: string;
    /**
     * 
     * @type {string}
     * @memberof SepaPaymentInstrumentResponse
     */
    beneficiaryBankBic: string;
    /**
     * 
     * @type {string}
     * @memberof SepaPaymentInstrumentResponse
     */
    transactionId?: string;
}

/**
 * Check if a given object implements the SepaPaymentInstrumentResponse interface.
 */
export function instanceOfSepaPaymentInstrumentResponse(value: object): value is SepaPaymentInstrumentResponse {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('applicantId' in value) || value['applicantId'] === undefined) return false;
    if (!('iban' in value) || value['iban'] === undefined) return false;
    if (!('beneficiaryBankName' in value) || value['beneficiaryBankName'] === undefined) return false;
    if (!('beneficiaryBankBic' in value) || value['beneficiaryBankBic'] === undefined) return false;
    return true;
}

export function SepaPaymentInstrumentResponseFromJSON(json: any): SepaPaymentInstrumentResponse {
    return SepaPaymentInstrumentResponseFromJSONTyped(json, false);
}

export function SepaPaymentInstrumentResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SepaPaymentInstrumentResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'applicantId': json['applicantId'],
        'iban': json['iban'],
        'beneficiaryFullName': json['beneficiaryFullName'] == null ? undefined : json['beneficiaryFullName'],
        'beneficiaryBankName': json['beneficiaryBankName'],
        'beneficiaryBankBic': json['beneficiaryBankBic'],
        'transactionId': json['transactionId'] == null ? undefined : json['transactionId'],
    };
}

  export function SepaPaymentInstrumentResponseToJSON(json: any): SepaPaymentInstrumentResponse {
      return SepaPaymentInstrumentResponseToJSONTyped(json, false);
  }

  export function SepaPaymentInstrumentResponseToJSONTyped(value?: SepaPaymentInstrumentResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'applicantId': value['applicantId'],
        'iban': value['iban'],
        'beneficiaryFullName': value['beneficiaryFullName'],
        'beneficiaryBankName': value['beneficiaryBankName'],
        'beneficiaryBankBic': value['beneficiaryBankBic'],
        'transactionId': value['transactionId'],
    };
}

