/* tslint:disable */
/* eslint-disable */
/**
 * Sell Checkout API
 * Sell Checkout API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-vilnius@simplex.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { KycVerificationStatus } from './KycVerificationStatus';
import {
    KycVerificationStatusFromJSON,
    KycVerificationStatusFromJSONTyped,
    KycVerificationStatusToJSON,
    KycVerificationStatusToJSONTyped,
} from './KycVerificationStatus';

/**
 * 
 * @export
 * @interface TransactionKycVerification
 */
export interface TransactionKycVerification {
    /**
     * 
     * @type {string}
     * @memberof TransactionKycVerification
     */
    inquiryId: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionKycVerification
     */
    uiContext: string;
    /**
     * 
     * @type {KycVerificationStatus}
     * @memberof TransactionKycVerification
     */
    status: KycVerificationStatus;
}



/**
 * Check if a given object implements the TransactionKycVerification interface.
 */
export function instanceOfTransactionKycVerification(value: object): value is TransactionKycVerification {
    if (!('inquiryId' in value) || value['inquiryId'] === undefined) return false;
    if (!('uiContext' in value) || value['uiContext'] === undefined) return false;
    if (!('status' in value) || value['status'] === undefined) return false;
    return true;
}

export function TransactionKycVerificationFromJSON(json: any): TransactionKycVerification {
    return TransactionKycVerificationFromJSONTyped(json, false);
}

export function TransactionKycVerificationFromJSONTyped(json: any, ignoreDiscriminator: boolean): TransactionKycVerification {
    if (json == null) {
        return json;
    }
    return {
        
        'inquiryId': json['inquiryId'],
        'uiContext': json['uiContext'],
        'status': KycVerificationStatusFromJSON(json['status']),
    };
}

  export function TransactionKycVerificationToJSON(json: any): TransactionKycVerification {
      return TransactionKycVerificationToJSONTyped(json, false);
  }

  export function TransactionKycVerificationToJSONTyped(value?: TransactionKycVerification | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'inquiryId': value['inquiryId'],
        'uiContext': value['uiContext'],
        'status': KycVerificationStatusToJSON(value['status']),
    };
}

