/* tslint:disable */
/* eslint-disable */
/**
 * Sell Checkout API
 * Sell Checkout API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-vilnius@simplex.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EmailVerificationAttempt } from './EmailVerificationAttempt';
import {
    EmailVerificationAttemptFromJSON,
    EmailVerificationAttemptFromJSONTyped,
    EmailVerificationAttemptToJSON,
    EmailVerificationAttemptToJSONTyped,
} from './EmailVerificationAttempt';

/**
 * 
 * @export
 * @interface EmailVerification
 */
export interface EmailVerification {
    /**
     * 
     * @type {string}
     * @memberof EmailVerification
     */
    id: string;
    /**
     * 
     * @type {EmailVerificationAttempt}
     * @memberof EmailVerification
     */
    attempt: EmailVerificationAttempt;
}

/**
 * Check if a given object implements the EmailVerification interface.
 */
export function instanceOfEmailVerification(value: object): value is EmailVerification {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('attempt' in value) || value['attempt'] === undefined) return false;
    return true;
}

export function EmailVerificationFromJSON(json: any): EmailVerification {
    return EmailVerificationFromJSONTyped(json, false);
}

export function EmailVerificationFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmailVerification {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'attempt': EmailVerificationAttemptFromJSON(json['attempt']),
    };
}

  export function EmailVerificationToJSON(json: any): EmailVerification {
      return EmailVerificationToJSONTyped(json, false);
  }

  export function EmailVerificationToJSONTyped(value?: EmailVerification | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'attempt': EmailVerificationAttemptToJSON(value['attempt']),
    };
}

