/* tslint:disable */
/* eslint-disable */
/**
 * Sell Checkout API
 * Sell Checkout API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-vilnius@simplex.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EmailVerificationAttempt
 */
export interface EmailVerificationAttempt {
    /**
     * 
     * @type {string}
     * @memberof EmailVerificationAttempt
     */
    id: string;
    /**
     * Time left to verify in seconds
     * @type {number}
     * @memberof EmailVerificationAttempt
     */
    timeLeft: number;
}

/**
 * Check if a given object implements the EmailVerificationAttempt interface.
 */
export function instanceOfEmailVerificationAttempt(value: object): value is EmailVerificationAttempt {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('timeLeft' in value) || value['timeLeft'] === undefined) return false;
    return true;
}

export function EmailVerificationAttemptFromJSON(json: any): EmailVerificationAttempt {
    return EmailVerificationAttemptFromJSONTyped(json, false);
}

export function EmailVerificationAttemptFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmailVerificationAttempt {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'timeLeft': json['timeLeft'],
    };
}

  export function EmailVerificationAttemptToJSON(json: any): EmailVerificationAttempt {
      return EmailVerificationAttemptToJSONTyped(json, false);
  }

  export function EmailVerificationAttemptToJSONTyped(value?: EmailVerificationAttempt | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'timeLeft': value['timeLeft'],
    };
}

