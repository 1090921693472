/* tslint:disable */
/* eslint-disable */
/**
 * Sell Checkout API
 * Sell Checkout API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-vilnius@simplex.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const CryptoLiquidationAgreementStatus = {
    Pending: 'pending',
    Confirmed: 'confirmed',
    Rejected: 'rejected'
} as const;
export type CryptoLiquidationAgreementStatus = typeof CryptoLiquidationAgreementStatus[keyof typeof CryptoLiquidationAgreementStatus];


export function instanceOfCryptoLiquidationAgreementStatus(value: any): boolean {
    for (const key in CryptoLiquidationAgreementStatus) {
        if (Object.prototype.hasOwnProperty.call(CryptoLiquidationAgreementStatus, key)) {
            if (CryptoLiquidationAgreementStatus[key as keyof typeof CryptoLiquidationAgreementStatus] === value) {
                return true;
            }
        }
    }
    return false;
}

export function CryptoLiquidationAgreementStatusFromJSON(json: any): CryptoLiquidationAgreementStatus {
    return CryptoLiquidationAgreementStatusFromJSONTyped(json, false);
}

export function CryptoLiquidationAgreementStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): CryptoLiquidationAgreementStatus {
    return json as CryptoLiquidationAgreementStatus;
}

export function CryptoLiquidationAgreementStatusToJSON(value?: CryptoLiquidationAgreementStatus | null): any {
    return value as any;
}

export function CryptoLiquidationAgreementStatusToJSONTyped(value: any, ignoreDiscriminator: boolean): CryptoLiquidationAgreementStatus {
    return value as CryptoLiquidationAgreementStatus;
}

