/* tslint:disable */
/* eslint-disable */
/**
 * Sell Checkout API
 * Sell Checkout API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-vilnius@simplex.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CryptoLiquidationAgreementStatus } from './CryptoLiquidationAgreementStatus';
import {
    CryptoLiquidationAgreementStatusFromJSON,
    CryptoLiquidationAgreementStatusFromJSONTyped,
    CryptoLiquidationAgreementStatusToJSON,
    CryptoLiquidationAgreementStatusToJSONTyped,
} from './CryptoLiquidationAgreementStatus';

/**
 * 
 * @export
 * @interface CryptoLiquidationAgreementResponse
 */
export interface CryptoLiquidationAgreementResponse {
    /**
     * crypto liquidation agreement
     * @type {string}
     * @memberof CryptoLiquidationAgreementResponse
     */
    id: string;
    /**
     * 
     * @type {CryptoLiquidationAgreementStatus}
     * @memberof CryptoLiquidationAgreementResponse
     */
    status: CryptoLiquidationAgreementStatus;
}



/**
 * Check if a given object implements the CryptoLiquidationAgreementResponse interface.
 */
export function instanceOfCryptoLiquidationAgreementResponse(value: object): value is CryptoLiquidationAgreementResponse {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('status' in value) || value['status'] === undefined) return false;
    return true;
}

export function CryptoLiquidationAgreementResponseFromJSON(json: any): CryptoLiquidationAgreementResponse {
    return CryptoLiquidationAgreementResponseFromJSONTyped(json, false);
}

export function CryptoLiquidationAgreementResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CryptoLiquidationAgreementResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'status': CryptoLiquidationAgreementStatusFromJSON(json['status']),
    };
}

  export function CryptoLiquidationAgreementResponseToJSON(json: any): CryptoLiquidationAgreementResponse {
      return CryptoLiquidationAgreementResponseToJSONTyped(json, false);
  }

  export function CryptoLiquidationAgreementResponseToJSONTyped(value?: CryptoLiquidationAgreementResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'status': CryptoLiquidationAgreementStatusToJSON(value['status']),
    };
}

