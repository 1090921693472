import { createParamDecorator, type ExecutionContext } from '@nestjs/common';
import type { Request } from 'express';
import { HeaderName } from '../enum/header-name.enum';

export const Prefer = createParamDecorator((property = HeaderName.PREFER, ctx: ExecutionContext) => {
  const { headers } = ctx.switchToHttp().getRequest<Request>();

  if (typeof property === 'string') {
    return headers[property.toLowerCase()];
  }

  return headers;
});
