/* tslint:disable */
/* eslint-disable */
/**
 * Sell Checkout API
 * Sell Checkout API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-vilnius@simplex.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TransactionPaymentInstrumentRecollectionProcess
 */
export interface TransactionPaymentInstrumentRecollectionProcess {
    /**
     * 
     * @type {string}
     * @memberof TransactionPaymentInstrumentRecollectionProcess
     */
    id: string;
    /**
     * process status
     * @type {string}
     * @memberof TransactionPaymentInstrumentRecollectionProcess
     */
    status: TransactionPaymentInstrumentRecollectionProcessStatus;
    /**
     * The email/phone value OTP will be sent to. Obfuscated for privacy reasons.
     * @type {string}
     * @memberof TransactionPaymentInstrumentRecollectionProcess
     */
    otpRecipientHint: string;
    /**
     * The hint to the user about the failed payment instrument. Obfuscated for privacy reasons.
     * @type {string}
     * @memberof TransactionPaymentInstrumentRecollectionProcess
     */
    failedPaymentInstrumentHint: string;
}


/**
 * @export
 */
export const TransactionPaymentInstrumentRecollectionProcessStatus = {
    Initiated: 'initiated',
    Completed: 'completed'
} as const;
export type TransactionPaymentInstrumentRecollectionProcessStatus = typeof TransactionPaymentInstrumentRecollectionProcessStatus[keyof typeof TransactionPaymentInstrumentRecollectionProcessStatus];


/**
 * Check if a given object implements the TransactionPaymentInstrumentRecollectionProcess interface.
 */
export function instanceOfTransactionPaymentInstrumentRecollectionProcess(value: object): value is TransactionPaymentInstrumentRecollectionProcess {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('status' in value) || value['status'] === undefined) return false;
    if (!('otpRecipientHint' in value) || value['otpRecipientHint'] === undefined) return false;
    if (!('failedPaymentInstrumentHint' in value) || value['failedPaymentInstrumentHint'] === undefined) return false;
    return true;
}

export function TransactionPaymentInstrumentRecollectionProcessFromJSON(json: any): TransactionPaymentInstrumentRecollectionProcess {
    return TransactionPaymentInstrumentRecollectionProcessFromJSONTyped(json, false);
}

export function TransactionPaymentInstrumentRecollectionProcessFromJSONTyped(json: any, ignoreDiscriminator: boolean): TransactionPaymentInstrumentRecollectionProcess {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'status': json['status'],
        'otpRecipientHint': json['otpRecipientHint'],
        'failedPaymentInstrumentHint': json['failedPaymentInstrumentHint'],
    };
}

  export function TransactionPaymentInstrumentRecollectionProcessToJSON(json: any): TransactionPaymentInstrumentRecollectionProcess {
      return TransactionPaymentInstrumentRecollectionProcessToJSONTyped(json, false);
  }

  export function TransactionPaymentInstrumentRecollectionProcessToJSONTyped(value?: TransactionPaymentInstrumentRecollectionProcess | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'status': value['status'],
        'otpRecipientHint': value['otpRecipientHint'],
        'failedPaymentInstrumentHint': value['failedPaymentInstrumentHint'],
    };
}

