import { createParamDecorator, type ExecutionContext } from '@nestjs/common';
import type { Request } from 'express';
import { HeaderName } from '../enum/header-name.enum';

export const IdempotenceKey = createParamDecorator((property = HeaderName.IDEMPOTENCE_KEY, ctx: ExecutionContext) => {
  const { headers } = ctx.switchToHttp().getRequest<Request>();

  if (typeof property === 'string' || typeof property === 'number') {
    return headers[property];
  }

  return headers;
});
