import { createParamDecorator, type ExecutionContext } from '@nestjs/common';
import type { Request } from 'express';
import { v5 } from 'uuid';
import { HeaderName } from '../enum/header-name.enum';

export const UUIDIdempotenceKey = createParamDecorator(
  (options: { property?: string | number; namespace?: string }, ctx: ExecutionContext) => {
    const { headers } = ctx.switchToHttp().getRequest<Request>();

    const idempotenceKey = headers[options.property ?? HeaderName.IDEMPOTENCE_KEY] as string | null;
    if (!idempotenceKey) {
      return null;
    }

    if (options.namespace) {
      return v5(options.namespace, idempotenceKey);
    }

    return idempotenceKey;
  },
);
