/* tslint:disable */
/* eslint-disable */
/**
 * Sell Checkout API
 * Sell Checkout API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-vilnius@simplex.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TransactionFiatPayoutProcess
 */
export interface TransactionFiatPayoutProcess {
    /**
     * process status
     * @type {string}
     * @memberof TransactionFiatPayoutProcess
     */
    status: TransactionFiatPayoutProcessStatus;
    /**
     * 
     * @type {string}
     * @memberof TransactionFiatPayoutProcess
     */
    fiatPayoutId: string;
}


/**
 * @export
 */
export const TransactionFiatPayoutProcessStatus = {
    Initiated: 'initiated',
    Finished: 'finished',
    Failed: 'failed',
    Processing: 'processing'
} as const;
export type TransactionFiatPayoutProcessStatus = typeof TransactionFiatPayoutProcessStatus[keyof typeof TransactionFiatPayoutProcessStatus];


/**
 * Check if a given object implements the TransactionFiatPayoutProcess interface.
 */
export function instanceOfTransactionFiatPayoutProcess(value: object): value is TransactionFiatPayoutProcess {
    if (!('status' in value) || value['status'] === undefined) return false;
    if (!('fiatPayoutId' in value) || value['fiatPayoutId'] === undefined) return false;
    return true;
}

export function TransactionFiatPayoutProcessFromJSON(json: any): TransactionFiatPayoutProcess {
    return TransactionFiatPayoutProcessFromJSONTyped(json, false);
}

export function TransactionFiatPayoutProcessFromJSONTyped(json: any, ignoreDiscriminator: boolean): TransactionFiatPayoutProcess {
    if (json == null) {
        return json;
    }
    return {
        
        'status': json['status'],
        'fiatPayoutId': json['fiatPayoutId'],
    };
}

  export function TransactionFiatPayoutProcessToJSON(json: any): TransactionFiatPayoutProcess {
      return TransactionFiatPayoutProcessToJSONTyped(json, false);
  }

  export function TransactionFiatPayoutProcessToJSONTyped(value?: TransactionFiatPayoutProcess | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'status': value['status'],
        'fiatPayoutId': value['fiatPayoutId'],
    };
}

